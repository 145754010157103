interface IMaintenancePageContent {
  header: string;
  subheader: string;
  description: string;
}

const content: IMaintenancePageContent = {
  header: 'Our Website is Currently Undergoing Maintenance',
  subheader: 'ThingCo is a new insurtech venture, established 1st January 2018 by insurethebox founder Mike Brockman. ',
  description: `We are a next generation TSP (telematics service provider), having built a suite of products for serving both the B2B and B2C markets. These products are revolutionary and address the weaknesses of current solutions in the market, building upon ThingCo’s deep understanding of the insurance and telematics markets.

  ThingCo’s B2C device “Theo” is available direct on drivetheo.com and Amazon. This is a first IoT in-car device where the data services provide access to cheap motor insurance, rewards for good driving and instant help in a motor accident.  
  `,
};

export default content;
