import React from 'react';
import { DisclaimerFooter, MaintenanceHeader, MaintenanceContent } from '../../components';
import { GlobalStyle } from '../../components/Layout/styles';
import content from './content';

const MaintenancePage = () => (
  <>
    <GlobalStyle />
    <MaintenanceHeader />
    <MaintenanceContent content={content} />
    <DisclaimerFooter />
  </>
);

export default MaintenancePage;
